/* Poppins */
@font-face {
    font-family: 'PoppinsMedium';
    src: local('PoppinsMedium'), url('./fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'PoppinsRegular';
    src: local('PoppinsRegular'), url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'PoppinsSemiBold';
    src: local('PoppinsSemiBold'), url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

/* Raleway */
@font-face {
    font-family: 'RalewayMedium';
    src: local('RalewayMedium'), url('./fonts/Raleway/Raleway-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'RalewayRegular';
    src: local('RalewayRegular'), url('./fonts/Raleway/Raleway-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'RalewaySemiBold';
    src: local('RalewaySemiBold'), url('./fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
}

/* Sacramento */
@font-face {
    font-family: 'SacramentoRegular';
    src: local('SacramentoRegular'),
        url('./fonts/Sacramento/Sacramento-Regular.ttf') format('truetype');
}

* {
    font-family: PoppinsRegular;
    box-sizing: border-box;
}

html,
body,
#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 4px;
}

@media (hover: hover) {
    ::-webkit-scrollbar-thumb:hover {
        background-color: #aaaaaa;
    }
}
